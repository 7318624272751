import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';
import { FormFieldSpec } from '~/components/organisms/modules/Form/FormMachine/FormMachine.types';

/**
 * A custom disclaimer acceptance field ID
 */
export const ACCEPT_DISCLAIMER_FIELD_ID = 'accept_disclaimer';

/**
 * A form row is simply a title and a set of fields
 */
export type FormRow = {
  title?: string;
  fields: Array<FormFieldSpec>;
};

type ConfirmationScreen = {
  confirmationType: 'confirmationScreen';
  confirmationScreen: {
    confirmationScreenContent: PortableTextCustomValueData;
  };
};

type ConfirmationRedirect = {
  confirmationType: 'redirect';
  confirmationRedirect: {
    url: string;
  };
};

/**
 * Props for the <Form> component
 */
export type FormProps = CMSModuleBase & {
  blurredBackground?: boolean;
  content: PortableTextCustomValueData;
  disclaimer: {
    consentLabel?: string;
    disclaimerContent?: PortableTextCustomValueData;
  };
  confirmation?: ConfirmationScreen | ConfirmationRedirect;
  rows: FormRow[];
  submitLabel?: string;
};

/**
 * Form submission state
 */
export type FormMessage = {
  type: 'error' | 'success';
};
