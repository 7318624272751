import { useSelector } from '@xstate/react';
import { SnapshotFrom } from 'xstate';

import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from '../Form.module.css';
import { ACCEPT_DISCLAIMER_FIELD_ID } from '../Form.types';
import { FormMachine } from '../FormMachine/FormMachine.types';
import { FormContentsProps } from './FormContents.types';
import Switch from './FormInput/Switch/Switch';
import FormRow from './FormRow/FormRow';
import SubmitButton from './SubmitButton/SubmitButton';

const fieldsSelector = (state: SnapshotFrom<FormMachine>) =>
  state.context.fields;

const FormContents = ({
  rows,
  disclaimerContent,
  consentLabel,
  submitLabel,
  form,
  shouldResetOnSubmit,
}: FormContentsProps) => {
  const formFields = useSelector(form, fieldsSelector);

  return (
    <>
      {rows.map((props, index) => (
        <FormRow key={props.title || index} {...props} machine={form} />
      ))}
      <div className={styles.row}>
        <div className={styles.submitRow}>
          {consentLabel && (
            <div className={styles.disclaimerButton}>
              <Switch machine={formFields[ACCEPT_DISCLAIMER_FIELD_ID]} />
            </div>
          )}
          {disclaimerContent && (
            <TextLockup
              className={cn(
                styles.disclaimerContent,
                consentLabel && styles.requiresConsent,
              )}
              value={disclaimerContent.blocks}
              lockupOptions={disclaimerContent.lockupOptions}
            />
          )}
          <div className={styles.submitWrapper}>
            <SubmitButton
              form={form}
              className={styles.submit}
              buttonColorScheme={'white'}
              label={submitLabel || dict('formSubmit')}
              shouldResetOnSubmit={shouldResetOnSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContents;
