'use client';
import { CSSProperties, ForwardedRef, forwardRef } from 'react';

import fabStyles from '~/components/organisms/modules/FloatingActionButton/FloatingActionButton.module.css';
import { ModuleWrapperProps } from '~/components/organisms/ModuleWrapper/ModuleWrapper.types';
import decorationsStyles from '~/styles/theme/decorations.module.css';
import { cn, getSpacersStyles } from '~/utils';

import styles from './ModuleWrapper.module.css';

const fabHeight = fabStyles.v_fabHeight;

/**
 * Base wrapper for module components. Handles shared functionality and applies background color and accent color options. Applies module slug.
 * @param slug string containing the module id/slug, set in CMS
 * @param accentColor string containing hex value of the module's accent color, set in CMS
 * @param hasSpacers If the module has spacers
 * @param role The module's role property
 * @param style dynamic style props to override default styles
 * @param sectionStackingOrder the module's parent section's stacking order
 * @param nextSectionStackingOrder the stacking order of the next section on the page
 * @param isFirstModule whether the module is the first module in the section
 * @param isLastModule whether the module is the last module in the section
 * @param children
 * @param className
 * @example  <ModuleWrapper><Bentos /></ModuleWrapper>
 */

const ModuleWrapper = (
  props: ModuleWrapperProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    slug,
    accentColor,
    zIndex,
    hasSpacers,
    role,
    style,
    children,
    className,
    sectionStackingOrder,
    nextSectionStackingOrder,
    isFirstModule,
    isLastModule,
    isRevealedModule,
    isCoveredModule,
    isLastSection,
    sectionIndex,
    precedesFab,
    followsFab,
    sectionHasFab,
  } = props;

  const isFirstPageModule = isFirstModule && sectionIndex === 0;

  // this value is used with spacer values to calculate the amount of extra padding needed to accomodate for overlap
  // only applies to the first and last modules in the section
  const isOverlappedTop =
    sectionIndex !== 0 && isFirstModule && sectionStackingOrder === 'below';
  const isOverlappedBottom =
    isLastModule && (nextSectionStackingOrder === 'above' || isLastSection);

  // if the module's parent section has rounded corners, we need to add some padding to the module to accomodate for the border-radius
  // modules with full width/height images don't need extra padding, as the image can extend to the rounded corners
  const hasRoundedTop = isFirstModule && sectionStackingOrder === 'above';
  const hasRoundedBottom = isLastModule && nextSectionStackingOrder === 'below';

  const isLastModuleInSectionWithFab = isLastModule && sectionHasFab;

  // the fab won't have any spacers applied, so we don't need to half the spacers
  const shouldHalfTopSpacers =
    !isRevealedModule && !isFirstPageModule && !followsFab;

  const shouldHalfBottomSpacers = isCoveredModule
    ? false
    : precedesFab
      ? false
      : isLastModuleInSectionWithFab
        ? false
        : true;

  const spacerValues = getSpacersStyles(
    hasSpacers?.before,
    hasSpacers?.after,
    shouldHalfTopSpacers,
    shouldHalfBottomSpacers,
  );

  const hasSpacerBefore = !hasRoundedTop && hasSpacers?.before;
  const hasSpacerAfter = !hasRoundedBottom && hasSpacers?.after;

  return (
    <article
      className={cn(
        className,
        styles.moduleWrapper,
        isRevealedModule && styles.isRevealedModule,
        isCoveredModule && styles.isCoveredModule,
        hasSpacerAfter && decorationsStyles.hasSpacerAfter,
        hasSpacerBefore && decorationsStyles.hasSpacerBefore,
        hasSpacerBefore &&
          isFirstPageModule &&
          decorationsStyles.isFirstPageModule,
        hasRoundedTop && styles.roundedTopPadding,
        hasRoundedBottom && styles.roundedBottomPadding,
        precedesFab && styles.precedesFab,
        followsFab && styles.followsFab,
        isLastModuleInSectionWithFab &&
          isCoveredModule &&
          styles.isCoveredLastModuleInSectionWithFab,
      )}
      ref={ref}
      id={slug}
      role={role}
      style={
        {
          ...(isLastModuleInSectionWithFab &&
            isCoveredModule && {
              '--fab-extra-space': `calc(${fabHeight}/2)`,
            }),
          ...(spacerValues as CSSProperties),
          ...(accentColor ? { '--color-accent': `var(${accentColor})` } : {}),
          ...(style ? style : {}),
          // used to calculate spacers for the first and last sections and for the pothole's aspect ratio
          ...(isOverlappedTop
            ? { '--section-overlap-top': 'var(--corner-radius)' }
            : {}),
          ...(isOverlappedBottom
            ? { '--section-overlap-bottom': 'var(--corner-radius)' }
            : {}),
          ...{ '--module-z-index': zIndex },
        } as CSSProperties
      }
    >
      {children}
    </article>
  );
};

export default forwardRef(ModuleWrapper);
