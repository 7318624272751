import { usePathname } from 'next/navigation';
import { Fragment } from 'react';

import DriftButton from '~/components/atoms/Buttons/Ctas/DriftButton/DriftButton';
import Link from '~/components/atoms/Link/Link';
import { cn } from '~/utils';

import { CMSFooterGroup } from '../Footer.types';
import styles from './FooterGroup.module.css';

const FooterGroup = ({
  group,
  linkItemClass,
}: {
  group: CMSFooterGroup;
  linkItemClass?: string;
}) => {
  const pathname = usePathname();
  return (
    <Fragment key={group._key}>
      <div>
        <p className={styles.groupLabel}>{group.footerGroupLabel}</p>
        {group.footerGroupLinks.map((item) => {
          const isCurrentPage = item._type === 'link' && pathname === item.url;
          const id = item._key;
          const classNames = cn(linkItemClass, styles.linkItem);
          return (
            <li className={styles.linkItemWrapper} key={id}>
              {item._type === 'driftButton' ? (
                <DriftButton
                  {...item.button}
                  buttonVariant="text"
                  className={classNames}
                />
              ) : (
                <Link
                  className={classNames}
                  to={item}
                  aria-current={isCurrentPage ? 'page' : false}
                >
                  {item.label}
                </Link>
              )}
            </li>
          );
        })}
      </div>
    </Fragment>
  );
};

export default FooterGroup;
