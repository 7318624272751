'use client';

import { useMachine, useSelector } from '@xstate/react';
import { useRouter } from 'next/navigation';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import { PortableTextCustomDataProps } from '~/components/molecules/PortableText/PortableText.types';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { formMachine } from '~/components/organisms/modules/Form/FormMachine/FormMachine';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './Form.module.css';
import { ACCEPT_DISCLAIMER_FIELD_ID, FormProps } from './Form.types';
import { getAllFormFields } from './Form.utils';
import FormContents from './FormContents/FormContents';

const Form = (props: FormProps) => {
  const router = useRouter();

  const {
    blurredBackground,
    content,
    rows,
    disclaimer,
    submitLabel,
    confirmation,
  } = props;
  const $innerRef = useRef<HTMLDivElement>(null);
  const [prevFormHeight, setPrevFormHeight] = useState(0);

  // take all the fields from all rows and return a flat array
  const fields = getAllFormFields(rows);

  // if the user needs to consent generate an additional field spec here for the consent disclaimer
  if (disclaimer.consentLabel) {
    fields.push({
      _type: 'switch',
      title: disclaimer.consentLabel,
      fieldId: ACCEPT_DISCLAIMER_FIELD_ID,
      isDisclaimer: true,
      span: 12,
      initialValue: false,
      required: true,
    });
  }

  const [, , actor] = useMachine(formMachine, {
    input: fields,
  });

  const formState = useSelector(actor, (state) => state.value);

  const buttonGroupConfirmationBlock =
    confirmation?.confirmationType === 'confirmationScreen' &&
    (confirmation?.confirmationScreen?.confirmationScreenContent?.blocks.find(
      (block) => block._type === 'block.buttonGroup',
    ) as PortableTextCustomDataProps);

  const numberOfConfirmationButtons = Math.max(
    3,
    buttonGroupConfirmationBlock && buttonGroupConfirmationBlock.buttonGroup
      ? buttonGroupConfirmationBlock.buttonGroup?.length
      : 0,
  );

  useEffect(() => {
    // Show confirmation screen
    if (formState !== 'confirmationScreen') {
      setPrevFormHeight($innerRef.current?.getBoundingClientRect().height || 0);
    }

    // Redirect on submission
    if (
      formState === 'submitted' &&
      confirmation?.confirmationType === 'redirect' &&
      confirmation?.confirmationRedirect
    ) {
      // Wait for the form to be submitted before redirecting
      setTimeout(() => {
        router.push(confirmation?.confirmationRedirect.url);
      }, 500);
    }
  }, [formState, router, confirmation]);

  return (
    <ModuleWrapper className={styles.wrapper} {...props}>
      <div
        className={cn(
          styles.inner,
          blurredBackground && styles.blurredBackground,
        )}
        ref={$innerRef}
        style={{
          minHeight:
            formState === 'confirmationScreen' ? prevFormHeight : 'auto',
        }}
      >
        {formState !== 'confirmationScreen' && (
          <>
            <div className={styles.headingWrapper}>
              <TextLockup
                className={styles.contentTextLockup}
                value={content.blocks}
                lockupOptions={content.lockupOptions}
              />
            </div>
            <form className={styles.form}>
              <FormContents
                form={actor}
                rows={rows}
                consentLabel={disclaimer.consentLabel}
                disclaimerContent={disclaimer.disclaimerContent}
                submitLabel={submitLabel}
                shouldResetOnSubmit={
                  !(
                    confirmation?.confirmationType === 'confirmationScreen' &&
                    confirmation?.confirmationScreen?.confirmationScreenContent
                  )
                }
              />
            </form>
          </>
        )}

        {confirmation?.confirmationType === 'confirmationScreen' &&
          confirmation?.confirmationScreen?.confirmationScreenContent &&
          formState === 'confirmationScreen' && (
            <div
              className={styles.confirmationScreenContent}
              style={
                { '--nb-buttons': numberOfConfirmationButtons } as CSSProperties
              }
            >
              <TextLockup
                value={
                  confirmation?.confirmationScreen.confirmationScreenContent
                    .blocks
                }
                lockupOptions={
                  confirmation?.confirmationScreen.confirmationScreenContent
                    .lockupOptions
                }
              />
            </div>
          )}
      </div>
    </ModuleWrapper>
  );
};

export default Form;
