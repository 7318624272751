import {
  PortableTextObject,
  PortableTextSpan,
  PortableTextTextBlock,
} from '@sanity/types';

import {
  ButtonColorSchemes,
  ButtonVariantOptions,
} from '~/components/atoms/Buttons/Ctas/ButtonBase.types';
import { CMSImage } from '~/components/atoms/Image/Image.types';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextButtonValue } from '~/components/molecules/PortableText/components/types/Button/Button.types';
import {
  PortableTextCustomDataProps,
  PortableTextCustomValue,
} from '~/components/molecules/PortableText/PortableText.types';
import { getUniqueId } from '~/utils';

import { FormRow } from '../Form/Form.types';
import { FormFieldSpec } from '../Form/FormMachine/FormMachine.types';
import { LivestreamEvent } from './Live.types';

/**
 * An instance of the date formatter for the en-US locale
 */
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
});

/**
 * Given a livestream event, return a human readable date only string
 *
 * @param event A livestream event
 * @returns string A human readable date
 */
export function getDisplayDate(event: LivestreamEvent): string {
  const date = new Date(event.date);
  if (date.toString() !== 'Invalid Date') {
    return dateFormatter.format(date);
  }
  return event.date;
}

/**
 * Given a livestream event, return a human readable date and time string
 *
 * @param event A livestream event
 * @returns string A human readable date
 */
export function getDisplayDateAndTime(event: LivestreamEvent): string {
  const date = new Date(event.date);
  if (date.toString() !== 'Invalid Date') {
    return dateTimeFormatter.format(date);
  }
  return event.date;
}

/**
 * Helper function to return a pseudo random key used for building up the
 * portable text data structure
 *
 * @returns string A random string key
 */
export const createUniqueKey = () => getUniqueId('live_');

/**
 * Form utility to generate form field/row data structures.
 */
export const FormUtil = {
  row(title: string, ...fields: FormFieldSpec[]): FormRow {
    return {
      title,
      fields,
    };
  },
  textInput(
    title: string,
    fieldId: string,
    inputType: 'text' | 'email' = 'text',
    span: 3 | 4 | 6 | 8 | 12 = 6,
    required: boolean = true,
  ): FormFieldSpec {
    return {
      _type: 'textInput',
      title,
      fieldId,
      required,
      span,
      inputType,
    };
  },
};

/**
 * Enhanced media utility to generate the appropriate data structure
 */
export const EnhancedMediaUtil = {
  fromCMSImage(image?: CMSImage): CMSEnhancedMedia {
    return {
      overlay: 'medium',
      media: {
        sanityMedia: {
          alt: '',
          type: 'image',
          asset: {
            dominantBackground: '#92a9be',
            darkVibrantBackground: '#251c0b',
            darkMutedBackground: '#4c3f32',
            lightMutedBackground: '#a3b9c9',
            isOpaque: true,
            height: image?.asset?.width || 0,
            width: image?.asset?.height || 0,
            aspectRatio: image?.asset?.aspectRatio || 0,
            _id: image?.asset?._id,
          },
          mediaType: 'image',
        },
      },
    } as CMSEnhancedMedia;
  },
};

/**
 * Generate PortableText data structures. The `_key` parameter is a locally
 * unique key within a single PortableText instance.
 *
 * https://github.com/portabletext/portabletext
 */
export const PortableTextUtil = {
  genkey: createUniqueKey,
  content(
    ...blocks: (
      | PortableTextTextBlock<PortableTextSpan | PortableTextObject>
      | PortableTextCustomDataProps
    )[]
  ): PortableTextCustomValue {
    return blocks as PortableTextCustomValue;
  },
  title(style: string, title: string, marks?: string[]) {
    return {
      markDefs: [],
      style,
      _key: createUniqueKey(),
      _type: 'block',
      children: [
        {
          marks: marks || [],
          text: title,
          _key: createUniqueKey(),
          _type: 'span',
        },
      ],
    } as PortableTextTextBlock<PortableTextObject>;
  },
  body(text: string, marks?: string[]) {
    return {
      children: [
        {
          _type: 'span',
          marks: marks || [],
          text: text,
          _key: createUniqueKey(),
        },
      ],
      markDefs: [],
      style: 'body',
      _key: createUniqueKey(),
      _type: 'block',
    } as PortableTextTextBlock<PortableTextObject>;
  },
  button(
    to: Record<string, unknown>,
    variant: ButtonVariantOptions = 'primary',
    colorScheme: ButtonColorSchemes = 'white',
  ) {
    return {
      _type: 'button',
      _key: createUniqueKey(),
      button: {
        _key: createUniqueKey(),
        buttonVariant: variant,
        buttonColorScheme: colorScheme,
        label: to.label || 'Click Here',
        to,
      },
    } as PortableTextButtonValue;
  },
  externalLink(label: string, url: string) {
    return {
      _key: createUniqueKey(),
      type: 'external',
      label,
      url,
    };
  },
  internalLink(label: string, url: string) {
    return {
      _key: createUniqueKey(),
      type: 'internal',
      label,
      url,
    } as CMSLinkProps;
  },
  buttonGroup(...buttons: PortableTextButtonValue[]) {
    return {
      _key: createUniqueKey(),
      _type: 'block.buttonGroup',
      markDefs: [],
      buttonGroup: buttons,
    } as PortableTextCustomDataProps;
  },
  svg(markup: string) {
    return {
      children: [],
      markDefs: [],
      _key: createUniqueKey(),
      _type: 'block.graphic',
      logo: {
        _id: crypto.randomUUID(),
        _type: 'graphic',
        type: 'svg',
        name: 'Frame.io Live',
        source: {
          markupString: markup,
          type: 'svg',
        },
      },
    } as PortableTextTextBlock<PortableTextObject>;
  },
};
